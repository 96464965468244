<template>
  <div class="fruit">
    <div class="img-box">
      <span class="fbc-spinner"></span>
    </div>

    <div class="fruit-content content-height">
      <p class="fruit-title blur">Xxxxxxxxxx</p>

      <ul class="fruit-attributes">
        <li class="select-structure blur">xxx gr.</li>
        <li class="fruit-attribute">
          <span class="blur"> $<strong> XX</strong> &mdash; gramo </span>
          <span class="fruit-action blur">Xxxxx</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script></script>

<style scoped>
.img-box {
  height: 30.3rem;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blur {
  filter: blur(5px);
}

.content-height {
}

.select-structure {
  padding: 1.6rem;
}

/* Below 704PX (Smaller Tablets) */
@media (max-width: 44em) {
  .img-box {
    height: 100%;
    width: 100%;
  }
}
</style>
