import { apiClient } from "./config/serviceConfiguration";

export default {
  createOrder(order) {
    return apiClient.post("/orders", order);
  },
  getOrder(orderId) {
    return apiClient.get("orders/" + orderId);
  },
  cancelOrder(orderId) {
    return apiClient.put("orders/" + orderId + "/cancel");
  },
};
