import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

router.beforeEach((to, from, next) => {
  store.state.order.showUserOptions = false;
  store.state.order.showCartSummary = false;
  next();
});

createApp(App).use(store).use(router).mount("#app");
