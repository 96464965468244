<template>
  <router-link
    :to="{ name: 'order', params: { id: order._id } }"
    class="order-in-progress-box"
  >
    <div class="order-detail">
      <strong
        class="order-state-small"
        :class="getOrderStateClass(order.orderState)"
      >
        {{ getOrderStateDescription(order.orderState) }}
      </strong>
    </div>

    <OrderStateProgressBarComponent
      v-if="
        order.orderState === 'CONFIRMATION' ||
        order.orderState === 'PREPARATION' ||
        order.orderState === 'DISTRIBUTION'
      "
      :order-state="order.orderState"
    />
  </router-link>
</template>

<script>
import {
  getOrderStateClass,
  getOrderStateDescription,
} from "@/features/transform";
import OrderStateProgressBarComponent from "@/components/order/OrderStateProgressBarComponent.vue";

export default {
  components: { OrderStateProgressBarComponent },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getOrderStateClass,
    getOrderStateDescription,
  },
};
</script>

<style scoped>
.order-in-progress-box {
  border: 1px solid #eee;
  font-size: 1.6rem;
  padding: 2rem;
  border-radius: 11px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.4rem;
  transition: all 0.2s;
  text-decoration: none;
  background-color: #fefefe;
  color: #495057;
}

.order-in-progress-box:hover {
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.07);
}

.order-detail {
  display: flex;
  justify-content: space-between;
}

.order-state-small {
  padding: 4px 7px;
  border-radius: 9px;
  font-weight: 600;
}

/* Below 704PX (Smaller Tablets) */
@media (max-width: 44em) {
  .order-in-progress-box {
    padding: 1.2rem;
  }
}
</style>
