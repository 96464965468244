<template>
  <main class="">
    <LoadingScreenComponent v-if="loginInProgress" />

    <section class="user-section">
      <div class="user-data-box">
        <h1 class="heading-primary">Iniciar Sesión</h1>

        <div class="google-button-container" id="googleSignupButton"></div>

        <div class="or-div">
          <span>o</span>
        </div>

        <form @submit="submit" class="user-form" autocomplete="on">
          <InputComponent
            label="Email"
            inputType="email"
            autocomplete="email"
            :model-value="email"
            :error="errors.email"
            @change="handleChange"
          />

          <InputComponent
            label="Contraseña"
            inputType="password"
            v-model="password"
            autocomplete="password"
            :error="errors.password"
          />

          <div class="user-form-actions">
            <button class="btn btn--full" type="submit">Iniciar Sesión</button>
            <button
              class="btn btn--register"
              type="button"
              @click="navigateSignup"
            >
              <span>Regístrate</span>
              <img
                src="../../assets/img/icons/register.svg"
                alt="register icon"
                draggable="false"
              />
            </button>
          </div>
        </form>
      </div>

      <ToastComponent ref="toast" />
    </section>
  </main>
</template>

<script>
import InputComponent from "@/components/shared/InputComponent.vue";
import { getCurrentInstance } from "vue";
import LoadingScreenComponent from "@/components/shared/LoadingScreenComponent.vue";
import router from "@/router";
import ToastComponent from "@/components/shared/ToastComponent.vue";
import { ToastTypes } from "@/features/toastTypes";
import { object, string } from "yup";
import { useField, useForm } from "vee-validate";

export default {
  data() {
    return {
      loginInProgress: false,
      credentials: {
        email: "",
        password: "",
      },
    };
  },
  setup() {
    const validationSchema = object({
      email: string()
        .email("Ingrese un email válido")
        .required("Debe ingresar un email"),
      password: string().required("Debe ingresar una contraseña"),
    });

    const { handleSubmit, errors } = useForm({
      validationSchema,
    });

    const { value: email, handleChange } = useField("email");
    const { value: password } = useField("password");

    const instance = getCurrentInstance();

    const submit = handleSubmit((values) => {
      instance.ctx.login(values);
    });

    return {
      email,
      password,
      submit,
      errors,
      handleChange,
    };
  },
  components: {
    ToastComponent,
    LoadingScreenComponent,
    InputComponent,
  },
  mounted() {
    this.scrollToTop();

    this.showGoogleButton();
  },
  methods: {
    async login(credentials) {
      this.loginInProgress = true;
      this.scrollToTop();
      try {
        await this.$store.dispatch("auth/login", credentials);
        if (this.cartLength > 0) {
          await router.push("/payment");
        } else {
          await router.push("/");
        }
      } catch (err) {
        this.$refs.toast.fire(ToastTypes.Error, err.response.data.message);
      }
      this.loginInProgress = false;
    },
    showGoogleButton() {
      const google = window.google;

      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.authWithGoogle,
        context: "signin",
      });

      google.accounts.id.renderButton(
        document.getElementById("googleSignupButton"),
        {
          theme: "filled_black",
          size: "large",
          shape: "pill",
          locale: "es",
        } // button attributes
      );

      // display the One Tap dialog
      google.accounts.id.prompt();
    },
    async authWithGoogle(googleResponse) {
      this.loginInProgress = true;
      this.scrollToTop();
      try {
        await this.$store.dispatch(
          "auth/authWithGoogle",
          googleResponse.credential
        );
        if (this.cartLength > 0) {
          await router.push("/payment");
        } else {
          await router.push("/");
        }
      } catch (err) {
        this.$refs.toast.fire(ToastTypes.Error, err.response.data.message);
      }
      this.loginInProgress = false;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    navigateSignup() {
      this.email = "";
      this.password = "";

      router.push("/signup");
    },
  },
  computed: {
    cartLength() {
      return this.$store.state.order.newOrder.items.length;
    },
  },
};
</script>

<style scoped>
.btn--register {
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  color: #495057;
}

.google-button-container {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.or-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 4rem;
}

.or-div:before,
.or-div:after {
  content: "";
  border-bottom: 1px solid #cfcfcf;
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
}

.or-div span {
  text-align: center;
  flex: 0.2 0 auto;
  margin: 0;
}
</style>
