import { createStore } from "vuex";

import * as product from "@/store/modules/product.js";
import * as order from "@/store/modules/order.js";
import * as address from "@/store/modules/address.js";
import * as user from "@/store/modules/user.js";
import * as auth from "@/store/modules/auth.js";

export default createStore({
  modules: {
    product,
    order,
    address,
    user,
    auth,
  },
});
