const { OrderState } = require("@/features/orderState");
exports.capitalizeName = (name) => {
  if (name) {
    return name.replace(/\b\w[\wáéíóúñ]*/g, function (match) {
      return match.charAt(0).toUpperCase() + match.slice(1);
    });
  } else {
    return "";
  }
};

// a javascript function that only capitalize the first letter of a string
exports.capitalizeFirstLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};

exports.renderLineBreaks = (text) => {
  return text.replace(/\n/g, "<br>");
};

exports.getOrderStateDescription = (orderState) => {
  switch (orderState) {
    case OrderState.Confirmation:
      return "Confirmación";
    case OrderState.Preparation:
      return "Preparación";
    case OrderState.Distribution:
      return "En camino";
    case OrderState.Delivered:
      return "Entregado";
    case OrderState.Cancelled:
      return "Cancelado";
    default:
      return orderState;
  }
};

exports.getOrderStateClass = (orderState) => {
  switch (orderState) {
    case OrderState.Confirmation:
      return "order-state--confirmation";
    case OrderState.Preparation:
      return "order-state--preparation";
    case OrderState.Distribution:
      return "order-state--distribution";
    case OrderState.Delivered:
      return "order-state--delivered";
    case OrderState.Cancelled:
      return "order-state--cancelled";
    default:
      return orderState;
  }
};
