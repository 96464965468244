<template>
  <button
    type="button"
    class="btn btn--full btn--continue"
    @click="goToPaymentAction"
    :disabled="isFetchingUserAddresses"
  >
    <span
      v-if="isFetchingUserAddresses"
      class="fbc-spinner fbc-spinner--small-white"
    ></span>
    <span>Ir al pago</span>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isFetchingUserAddresses: false,
    };
  },
  methods: {
    async goToPaymentAction() {
      if (!this.$store.getters["auth/loggedIn"]) {
        this.$router.push("/login");
        return;
      }

      this.isFetchingUserAddresses = true;
      await Promise.all([
        this.$store.dispatch("user/getMe"),
        this.$store.dispatch("address/getAllUserAddresses"),
      ]);
      this.isFetchingUserAddresses = false;

      this.$store.dispatch("order/toggleShowCartSummary");

      if (this.$store.getters["address/isFirstUserAddress"]) {
        this.$router.push("/first-address");
      } else {
        this.$router.push("/payment");
      }
    },
  },
};
</script>

<style scoped></style>
