<template>
  <main class="">
    <LoadingScreenComponent />

    <section class="user-section"></section>
  </main>
</template>

<script>
import LoadingScreenComponent from "@/components/shared/LoadingScreenComponent.vue";
import router from "@/router";

export default {
  props: ["impersonateToken"],
  components: {
    LoadingScreenComponent,
  },
  async created() {
    try {
      await this.$store.dispatch("auth/impersonateUser", this.impersonateToken);
      this.$store.dispatch("auth/setSuccessImpersonate", true);
      await router.push({ name: "home" });
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    async login() {
      await router.push({ name: "home" });
    },
  },
  computed: {},
};
</script>

<style scoped></style>
