<template>
  <HeaderComponent />

  <router-view />

  <FooterComponent />
</template>

<script>
import HeaderComponent from "@/components/shared/HeaderComponent.vue";
import FooterComponent from "@/components/shared/FooterComponent.vue";
import router from "@/router";
import { apiClient } from "@/services/config/serviceConfiguration";

export default {
  components: { FooterComponent, HeaderComponent },
  created() {
    const userString = localStorage.getItem("userData");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.dispatch("auth/setUserData", userData);
    }

    apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("auth/logoutInterceptor");
          router.push("/login");
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
@import url(assets/css/general.css);
@import url(assets/css/queries.css);
@import url(assets/css/style.css);
@import url(assets/css/vue-multiselect.css);
@import url(assets/css/spinners.css);
</style>
