<template>
  <div class="big-input-field">
    <input
      v-bind="$attrs"
      placeholder="Nombre de producto"
      v-model="searchFilter"
      :id="uuid"
      :aria-describedby="error ? `${uuid}-error` : null"
      :aria-invalid="error ? true : null"
      class="big-input-field-box"
      :class="error ? 'input-error' : null"
      autocomplete="off"
      ref="inputSearch"
      @input="handleInputChange"
    />
  </div>
  <p
    v-if="error"
    class="errorMessage"
    :id="`${uuid}-error`"
    aria-live="assertive"
  >
    {{ error }}
  </p>
</template>

<script>
import UniqueID from "@/features/uniqueID";

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    home: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
  },
  setup() {
    const uuid = UniqueID();
    return {
      uuid,
    };
  },
  mounted() {
    this.$refs.inputSearch.focus();
  },
  computed: {
    searchFilter: {
      get() {
        return this.$store.state.product.searchFilter;
      },
      set(newValue) {
        this.$store.dispatch("product/setSearchFilter", newValue);
      },
    },
  },
  methods: {
    handleInputChange() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.big-input-field {
  width: 52rem;
}

.big-input-field-box {
  width: 100%;
  height: 50px;

  color: #333;
  border: 1px solid #dcdcdc;
  border-radius: 54px;
  font-size: 1.8rem;
  padding-left: 4.6rem;

  /* Loupe */
  background: #fefefe url("@/assets/img/icons/search.svg") no-repeat 1.6rem
    center;
  transition: all 0.2s;
}

.big-input-field-box:hover {
  border: 1px solid #2eb086;
}

.big-input-field-box:focus {
  border: 1px solid #2eb086;
  outline: rgba(46, 176, 134, 0.2) 2px solid;
  background-color: rgba(46, 176, 134, 0.025);
}

.big-input-field-label--home {
  font-weight: 600;
}

/* Placeholder color configuration for different browsers */

/* Chrome, Firefox, Opera, Safari 10.1+ */
::placeholder {
  color: #b3b3b3;
  opacity: 1;
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  color: #b3b3b3;
}

/* Microsoft Edge */
::-ms-input-placeholder {
  color: #b3b3b3;
}

@media (max-width: 59em) {
  .big-input-field-label--home {
    color: #fff;
  }
}
</style>
