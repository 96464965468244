import {
  setHeaderToken,
  removeHeaderToken,
} from "@/services/config/serviceConfiguration";
import authService from "@/services/authService";

export const namespaced = true;

export const state = {
  userData: null,
  successImpersonate: false,
};

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.userData = userData;
    localStorage.setItem("userData", JSON.stringify(userData));
    setHeaderToken(userData.token);
  },
  UPDATE_USER_DATA_USER(state, user) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    userData.user = user;
    localStorage.setItem("userData", JSON.stringify(userData));
    state.userData = userData;
  },
  UPDATE_USER_DELIVERY_ADDRESS(state, addressId) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    userData.user.defaultDeliveryAddress = addressId;
    localStorage.setItem("userData", JSON.stringify(userData));
    state.userData = userData;
  },
  LOGOUT(state) {
    const email = state.userData.user.email;
    const google = window.google;

    google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    });

    google.accounts.id.revoke(email, () => {
      localStorage.removeItem("userData");
      localStorage.clear();
      location.reload();
      removeHeaderToken();
    });
  },
  LOGOUT_INTERCEPTOR(state) {
    if (state.userData) {
      state.userData = null;
      localStorage.removeItem("userData");
      localStorage.clear();
      location.reload();
      removeHeaderToken();
    }
  },
  SET_SUCCESS_IMPERSONATE(state, value) {
    state.successImpersonate = value;
  },
};

export const actions = {
  async signup({ commit }, newUser) {
    const userDB = await authService.signup(newUser);
    commit("SET_USER_DATA", userDB.data.data);
  },
  async login({ commit }, credentials) {
    const userDB = await authService.login(credentials);
    commit("SET_USER_DATA", userDB.data.data);
  },
  async authWithGoogle({ commit }, credentialToken) {
    const credential = {
      credential: credentialToken,
    };

    const userDB = await authService.authWithGoogle(credential);
    commit("SET_USER_DATA", userDB.data.data);
  },
  logout({ commit }) {
    commit("LOGOUT");
  },
  logoutInterceptor({ commit }) {
    commit("LOGOUT_INTERCEPTOR");
  },
  setUserData({ commit }, userData) {
    commit("SET_USER_DATA", userData);
  },
  updateUserDataUser({ commit }, user) {
    commit("UPDATE_USER_DATA_USER", user);
  },
  async impersonateUser({ commit }, impersonateToken) {
    const userDB = await authService.impersonateUser(impersonateToken);
    commit("SET_USER_DATA", userDB.data.data);
  },
  setSuccessImpersonate({ commit }, value) {
    commit("SET_SUCCESS_IMPERSONATE", value);
  },
  updateUserDeliveryAddress({ commit }, addressId) {
    commit("UPDATE_USER_DELIVERY_ADDRESS", addressId);
  },
};

export const getters = {
  loggedIn(state) {
    return !!state.userData;
  },
  isAdminUser(state) {
    if (state.userData) {
      return state.userData.user.role === "ADMIN";
    } else {
      return false;
    }
  },
  getUser(state) {
    if (state.userData) {
      return state.userData.user;
    }
  },
};
