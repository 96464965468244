import { apiClient } from "./config/serviceConfiguration";

export default {
  getAllCountries() {
    return apiClient.get("/countries");
  },
  getDepartmentsByCountry(countryId) {
    return apiClient.get("/countries/" + countryId + "/departments");
  },
  getCitiesByDepartment(departmentId) {
    return apiClient.get("/departments/" + departmentId + "/cities");
  },
  createAddress(address) {
    return apiClient.post("/addresses", address);
  },
  deleteAddress(addressId) {
    return apiClient.delete("/addresses/" + addressId);
  },
};
