import productService from "@/services/productService";
export const namespaced = true;

export const state = {
  products: null,
  searchFilter: "",
  showInputSearchInHeader: false,
};

export const mutations = {
  SET_SEARCH_FILTER(state, value) {
    state.searchFilter = value;
  },
  SET_SHOW_INPUT_SEARCH_IN_HEADER(state, value) {
    state.showInputSearchInHeader = value;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
};

export const actions = {
  setSearchFilter({ commit }, value) {
    commit("SET_SEARCH_FILTER", value);
  },
  setShowInputSearchInHeader({ commit }, value) {
    commit("SET_SHOW_INPUT_SEARCH_IN_HEADER", value);
  },
  async fetchAllProducts({ commit }) {
    const response = await productService.getAllCatalogProducts();
    commit("SET_PRODUCTS", response.data.data.products);
  },
};

export const getters = {
  getAllProducts(state) {
    return state.products;
  },
};
