import orderService from "@/services/orderService";
import userService from "@/services/userService";
import paymentService from "@/services/paymentService";

export const namespaced = true;

export const state = {
  // TODO: Centralize the new order object values
  newOrder: {
    user: null,
    orderTotalPrice: 0,
    items: [],
    shippingAddress: null,
    payment: {
      paymentMethod: null,
    },
    description: "",
  },
  showCartSummary: false,
  showUserOptions: false,
  paymentMethods: null,
  createdOrder: null,
  pendingOrders: [],
  selectedOrder: null,
  userAllOrders: null,
  showCancelOrderPopup: false,

  // validators
  errorShippingAddress: false,
  errorPaymentMethod: false,
  errorSelectedUser: false,
  errorUserData: false,
};

export const mutations = {
  SET_CREATED_ORDER(state, orderCreated) {
    state.createdOrder = orderCreated;
  },
  SET_SHIPPING_ADDRESS(state, shippingAddress) {
    state.newOrder.shippingAddress = shippingAddress;
  },
  ADD_TO_CART(state, item) {
    state.newOrder.items.push(item);
  },
  REMOVE_FROM_CART(state, item) {
    const index = state.newOrder.items.indexOf(item);
    if (index !== -1) {
      state.newOrder.items.splice(index, 1);
    }
  },
  TOGGLE_SHOW_CART_SUMMARY(state) {
    state.showCartSummary = !state.showCartSummary;
  },
  TOGGLE_SHOW_USER_OPTIONS(state) {
    state.showUserOptions = !state.showUserOptions;
  },
  SET_SHOW_CART_SUMMARY(state, value) {
    state.showCartSummary = value;
  },
  SET_SHOW_USER_OPTIONS(state, value) {
    state.showUserOptions = value;
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  SET_PAYMENT_METHOD(state, paymentMethod) {
    state.newOrder.payment.paymentMethod = paymentMethod;
  },
  SET_ORDER_TOTAL_PRICE(state, totalPrice) {
    state.newOrder.orderTotalPrice = totalPrice;
  },
  SET_ORDER_USER(state, appUser) {
    state.newOrder.user = appUser._id;
  },
  RESET_NEW_ORDER(state) {
    state.newOrder = {
      user: null,
      orderTotalPrice: 0,
      items: [],
      shippingAddress: null,
      payment: {
        paymentMethod: null,
      },
      description: "",
    };
  },
  SET_PENDING_ORDERS(state, pendingOrders) {
    state.pendingOrders = pendingOrders;
  },
  SET_SELECTED_ORDER(state, order) {
    state.selectedOrder = order;
  },
  SET_ALL_USER_ORDERS(state, allUserOrders) {
    state.userAllOrders = allUserOrders;
  },
  SET_SHOW_CANCEL_ORDER_POPUP(state, value) {
    state.showCancelOrderPopup = value;
  },
  SET_ERROR_SHIPPING_ADDRESS(state, value) {
    state.errorShippingAddress = value;
  },
  SET_ERROR_PAYMENT_METHOD(state, value) {
    state.errorPaymentMethod = value;
  },
  SET_ERROR_SELECTED_USER(state, value) {
    state.errorSelectedUser = value;
  },
};

export const actions = {
  async createUserOrder({ commit, rootGetters }) {
    commit("SET_ORDER_TOTAL_PRICE", rootGetters["order/orderTotalPrice"]);
    const createdOrderResponse = await userService.createUserOrder(
      state.newOrder
    );

    commit("SET_CREATED_ORDER", createdOrderResponse.data.data.order);
    commit("RESET_NEW_ORDER");
  },
  async createAdminOrder({ commit, rootGetters }) {
    commit("SET_ORDER_TOTAL_PRICE", rootGetters["order/orderTotalPrice"]);
    commit("SET_ORDER_USER", rootGetters["user/getSelectedUser"]);
    const createdOrderResponse = await orderService.createOrder(state.newOrder);

    commit("SET_CREATED_ORDER", createdOrderResponse.data.data.order);
    commit("RESET_NEW_ORDER");
  },
  setShippingAddress({ commit }, shippingAddress) {
    commit("SET_SHIPPING_ADDRESS", shippingAddress);
  },
  addToCart({ commit }, item) {
    commit("ADD_TO_CART", item);
  },
  removeFromCart({ commit }, product) {
    commit("REMOVE_FROM_CART", product);
  },
  toggleShowCartSummary({ commit }) {
    commit("TOGGLE_SHOW_CART_SUMMARY");
  },
  toggleShowUserOptions({ commit }) {
    commit("TOGGLE_SHOW_USER_OPTIONS");
  },
  setShowCartSummary({ commit }, value) {
    commit("SET_SHOW_CART_SUMMARY", value);
  },
  setShowUserOptions({ commit }, value) {
    commit("SET_SHOW_USER_OPTIONS", value);
  },
  async getAllPaymentMethods({ commit }) {
    const response = await paymentService.getAllPaymentMethods();
    commit("SET_PAYMENT_METHODS", response.data.data.paymentMethods);
  },
  setPaymentMethod({ commit }, paymentMethod) {
    commit("SET_PAYMENT_METHOD", paymentMethod);
  },
  async fetchOrdersInProgress({ commit, rootGetters }) {
    const appUser = rootGetters["auth/getUser"];
    if (appUser) {
      const response = await userService.getMyPendingOrders();
      commit("SET_PENDING_ORDERS", response.data.data.orders);
    }
  },
  async fetchOrder({ commit }, orderId) {
    const response = await orderService.getOrder(orderId);
    commit("SET_SELECTED_ORDER", response.data.data.order);
  },
  async fetchAllUserOrders({ commit, rootGetters }) {
    const appUser = rootGetters["auth/getUser"];
    if (appUser) {
      const response = await userService.getMyOrders();
      commit("SET_ALL_USER_ORDERS", response.data.data.orders);
    }
  },
  setShowCancelOrderPopup({ commit }, value) {
    commit("SET_SHOW_CANCEL_ORDER_POPUP", value);
  },
  async cancelOrder({ commit }, orderId) {
    const response = await orderService.cancelOrder(orderId, {
      orderState: "CANCELLED",
    });
    commit("SET_SELECTED_ORDER", response.data.data.order);
  },
  setErrorShippingAddress({ commit }, value) {
    commit("SET_ERROR_SHIPPING_ADDRESS", value);
  },
  setErrorPaymentMethod({ commit }, value) {
    commit("SET_ERROR_PAYMENT_METHOD", value);
  },
  setErrorSelectedUser({ commit }, value) {
    commit("SET_ERROR_SELECTED_USER", value);
  },
  async repeatOrder({ commit, dispatch, rootGetters }) {
    // reset cart
    commit("RESET_NEW_ORDER");

    const selectedOrderItems = state.selectedOrder.items;

    // fetch the products
    await dispatch("product/fetchAllProducts", null, { root: true });
    const allProducts = rootGetters["product/getAllProducts"];

    for (const repeatItem of selectedOrderItems) {
      // if product is present in the catalog and not present in cart
      const productPresentInCatalogWithStock = allProducts.some(
        (p) =>
          p._id === repeatItem.product._id &&
          !(p.stockQuantity <= p.outOfStockTrigger)
      );
      const productNotPresentInCart = !state.newOrder.items.some(
        (i) => i.product._id === repeatItem.product._id
      );

      if (productPresentInCatalogWithStock && productNotPresentInCart) {
        const product = allProducts.find(
          (p) => p._id === repeatItem.product._id
        );
        const quantity =
          repeatItem.quantity > product.stockQuantity
            ? product.stockQuantity
            : repeatItem.quantity;

        const newItem = {
          product: product,
          quantity: quantity,
        };

        commit("ADD_TO_CART", newItem);
      }
    }
  },
};

export const getters = {
  orderTotalPrice: (state) => {
    const totalPriceArray = state.newOrder.items.map(
      (i) => i.product.price * i.quantity
    );

    if (totalPriceArray.length === 0) {
      return 0;
    } else {
      return totalPriceArray.reduce((a, b) => a + b);
    }
  },
};
