import addressService from "@/services/addressService";
import userService from "@/services/userService";

export const namespaced = true;

export const state = {
  userAddresses: null,
  countries: [],
  departments: [],
  cities: [],
};

export const mutations = {
  SET_USER_ADDRESSES(state, addresses) {
    state.userAddresses = addresses;
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  SET_DEPARTMENTS(state, departments) {
    state.departments = departments;
  },
  SET_CITIES(state, cities) {
    state.cities = cities;
  },
  ADD_USER_ADDRESS(state, address) {
    state.userAddresses.push(address);
  },
  DELETE_USER_ADDRESS(state, address) {
    const index = state.userAddresses.indexOf(address);
    if (index !== -1) {
      state.userAddresses.splice(index, 1);
    }
  },
  UPDATE_USER_ADDRESS(state, address) {
    const index = state.userAddresses.findIndex((a) => a._id === address._id);
    if (index !== -1) {
      state.userAddresses[index] = address;
    }
  },
};

export const actions = {
  async getAllUserAddresses({ commit }) {
    let response = await userService.getMyAddresses();
    commit("SET_USER_ADDRESSES", response.data.data.addresses);
  },
  async getAllCountries({ commit }) {
    const response = await addressService.getAllCountries();
    commit("SET_COUNTRIES", response.data.data.countries);
  },
  async getDepartmentsByCountry({ commit }, countryId) {
    const response = await addressService.getDepartmentsByCountry(countryId);
    commit("SET_CITIES", []);
    commit("SET_DEPARTMENTS", response.data.data.departments);
  },
  async getCitiesByDepartment({ commit }, departmentId) {
    const response = await addressService.getCitiesByDepartment(departmentId);
    commit("SET_CITIES", response.data.data.cities);
  },
  async createAddress({ commit, dispatch }, address) {
    const response = await userService.createUserAddress(address);
    const newAddress = response.data.data.address;
    commit("ADD_USER_ADDRESS", newAddress);
    dispatch("auth/updateUserDeliveryAddress", newAddress._id, {
      root: true,
    });
  },
  async updateAddress({ commit }, payload) {
    const response = await userService.updateUserAddress(
      payload.id,
      payload.address
    );
    commit("UPDATE_USER_ADDRESS", response.data.data.address);
  },
  async deleteAddress({ commit }, address) {
    await userService.deleteUserAddress(address._id);
    commit("DELETE_USER_ADDRESS", address);
  },
  async setDefaultUserDeliveryAddress({ dispatch }, addressId) {
    dispatch("auth/updateUserDeliveryAddress", addressId, {
      root: true,
    });
    await userService.setDefaultUserDeliveryAddress({
      addressId,
    });
  },
  resetAddressFields({ commit }) {
    commit("SET_COUNTRIES", []);
    commit("SET_DEPARTMENTS", []);
    commit("SET_CITIES", []);
  },
};

export const getters = {
  isFirstUserAddress(state) {
    if (state.userAddresses) {
      return state.userAddresses.length === 0;
    } else {
      return false;
    }
  },
};
