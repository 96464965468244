<template>
  <div class="cta">
    <div class="cta-text-box">
      <h2 class="heading-contact">Contacto</h2>
      <p class="cta-text">
        Realizá tu pedido, contactanos por cualquiera de estos medios y
        atenderemos a tu solicitud.
      </p>

      <div class="cta-form">
        <a
          target="_blank"
          rel="noopener noreferrer"
          class="btn btn--form btn--contact"
          href="https://wa.link/7rsdn5"
        >
          <div class="contact-box">
            <img
              src="@/assets/img/icons/whatsapp-white.svg"
              class="contact-icon"
              alt="mail icon"
              draggable="false"
            />
            <span class="contact-text-1">WhatsApp</span>
            <span class="contact-text-2">321&nbsp;4318769</span>
          </div>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          class="btn btn--form btn--contact"
          href="https://www.instagram.com/frutos_fbc/"
        >
          <div class="contact-box">
            <img
              src="@/assets/img/icons/instagram-white.svg"
              class="contact-icon"
              alt="instagram icon"
              draggable="false"
            />
            <span class="contact-text-1">Instagram</span>
            <span class="contact-text-2">@frutos_fbc</span>
          </div>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          class="btn btn--form btn--contact"
          href="tel:+573214318769"
        >
          <div class="contact-box">
            <img
              src="@/assets/img/icons/phone-white.svg"
              class="contact-icon"
              alt="phone icon"
              draggable="false"
            />
            <span class="contact-text-1">Teléfono</span>
            <span class="contact-text-2">+57&nbsp;321&nbsp;4318769</span>
          </div>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          class="btn btn--form btn--contact"
          href="mailto:contabilidad@fbc.com.co"
        >
          <div class="contact-box">
            <img
              src="@/assets/img/icons/mail-white.svg"
              class="contact-icon"
              alt="mail icon"
              draggable="false"
            />
            <span class="contact-text-1">Email</span>
            <span class="contact-text-2">contabilidad@fbc.com.co</span>
          </div>
        </a>
      </div>
    </div>
    <div class="cta-img-box" role="img" aria-label="Dry food plate"></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.heading-contact {
  font-size: 4rem;
  margin-bottom: 3.2rem;
}
</style>
