<template>
  <div class="progress-bar">
    <div class="progress-bar-state" :class="getConfirmationStateClass()">
      <img
        src="@/assets/img/icons/confirmation-green.svg"
        v-if="getConfirmationStateClass() === 'progress-bar-state--done'"
        alt="done mark"
      />
      <img
        v-else
        src="@/assets/img/icons/confirmation-white.svg"
        alt="done mark"
      />
    </div>
    <div class="progress-bar-state" :class="getPreparationStateClass()">
      <img
        src="@/assets/img/icons/preparation-green.svg"
        v-if="getPreparationStateClass() === 'progress-bar-state--done'"
        alt="preparation icon"
      />
      <img
        v-if="getPreparationStateClass() === 'progress-bar-state--actual'"
        src="@/assets/img/icons/preparation-white.svg"
        alt="preparation icon"
      />
      <img
        v-if="!getPreparationStateClass()"
        src="@/assets/img/icons/preparation-dark.svg"
        alt="preparation icon"
      />
    </div>
    <div class="progress-bar-state" :class="getDistributionStateClass()">
      <img
        src="@/assets/img/icons/delivery-green.svg"
        v-if="getDistributionStateClass() === 'progress-bar-state--done'"
        alt="done mark"
      />
      <img
        v-if="getDistributionStateClass() === 'progress-bar-state--actual'"
        src="@/assets/img/icons/delivery-white.svg"
        alt="delivery icon"
      />
      <img
        v-if="!getDistributionStateClass()"
        src="@/assets/img/icons/delivery-dark.svg"
        alt="delivery icon"
      />
    </div>
  </div>
</template>

<script>
import { OrderState } from "@/features/orderState";

export default {
  props: {
    orderState: {
      type: String,
      required: true,
    },
  },
  methods: {
    getConfirmationStateClass() {
      switch (this.orderState) {
        case OrderState.Confirmation:
          return "progress-bar-state--actual";
        case OrderState.Preparation:
          return "progress-bar-state--done";
        case OrderState.Distribution:
          return "progress-bar-state--done";
        case OrderState.Delivered:
          return "progress-bar-state--done";
        default:
          return "";
      }
    },
    getPreparationStateClass() {
      switch (this.orderState) {
        case OrderState.Preparation:
          return "progress-bar-state--actual";
        case OrderState.Distribution:
          return "progress-bar-state--done";
        case OrderState.Delivered:
          return "progress-bar-state--done";
        default:
          return "";
      }
    },
    getDistributionStateClass() {
      switch (this.orderState) {
        case OrderState.Distribution:
          return "progress-bar-state--actual";
        case OrderState.Delivered:
          return "progress-bar-state--done";
        default:
          return "";
      }
    },
    getDeliveredStateClass() {
      switch (this.orderState) {
        case OrderState.Delivered:
          return "progress-bar-state--done";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.progress-bar {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 9px;
  overflow: hidden;
  border: 1px solid #eee;
}

.progress-bar-state {
  padding: 1.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  border-right: 1px solid #eee;
  color: #adb5bd;
}

.progress-bar-state--done {
  background-color: #c3fae8;
  color: #087f5b;
  border-right: 1px solid #fff;
}

.progress-bar-state--actual {
  background-color: #087f5b;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  color: #fff;
}

.progress-bar-state:nth-last-child(1) {
  border: none;
}

/* Shine effect */
.progress-bar-state--actual:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  animation: slide 1s infinite;

  /* Gradient */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.8)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    color-stop(100%, rgba(125, 185, 232, 0))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
