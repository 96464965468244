<template>
  <footer class="footer">
    <div class="container grid grid--3-cols">
      <div class="logo-col">
        <div class="footer-logo">
          <a class="fbc-logo" href="#"> FBC </a>
        </div>

        <ul class="social-links">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              class="footer-link"
              href="https://www.instagram.com/frutos_fbc/"
            >
              <img
                src="@/assets/img/icons/instagram.svg"
                class="social-icon"
                alt="instagram icon"
                draggable="false"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              class="footer-link"
              href="https://web.whatsapp.com/send?phone=573214318769&text="
            >
              <img
                src="@/assets/img/icons/whatsapp.svg"
                class="social-icon"
                alt="whatsapp icon"
                draggable="false"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              class="footer-link"
              href="mailto:contabilidad@fbc.com.co"
            >
              <img
                src="@/assets/img/icons/mail.svg"
                class="social-icon"
                alt="mail icon"
                draggable="false"
              />
            </a>
          </li>
        </ul>

        <p class="copyright">
          Copyright &copy; <span class="year">{{ getYear }}</span> FBC, Inc.
          <br />
          Todos los derechos reservados.
        </p>
        <p class="copyright"></p>
      </div>

      <div class="address-col">
        <p class="footer-heading">Sucursal</p>
        <address class="contacts">
          <p class="address">
            Rivera - Huila
            <br />
            Calle 2 sur, 7-44
            <br />
            Barrio los Fundadores
            <br />
            Conjunto Piamonte - Casa 0
          </p>
        </address>
      </div>

      <div class="address-col">
        <p class="footer-heading">Contacto</p>
        <div class="contacts">
          <a class="footer-link" href="tel:+573214318769"> +57 321 4318769 </a>
          <br />
          <a class="footer-link" href="mailto:contabilidad@fbc.com.co">
            contabilidad@fbc.com.co
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style></style>
