<template>
  <div class="summary-box">
    <div class="close-btn-container">
      <button @click="toggleShowUserOptions" class="hide-label">Ocultar</button>
      <button
        class="btn-close-summary-box"
        @click="toggleShowUserOptions"
        title="Ocultar"
      >
        <img
          draggable="false"
          src="@/assets/img/icons/arrow-up.svg"
          alt="icono de carrito"
        />
      </button>
    </div>

    <div v-if="isLoggedIn" class="summary-box-content">
      <div class="user-details">
        <strong>
          {{ capitalizeName(user.firstName ? user.firstName : "") }}
          {{ capitalizeName(user.lastName ? user.lastName : "") }}
        </strong>
        <p>{{ user.email }}</p>
      </div>

      <div class="user-options-navigation">
        <router-link :to="{ name: 'user' }" class="btn btn--edit-user-data">
          Mi usuario
        </router-link>
        <router-link
          :to="{ name: 'addresses' }"
          class="btn btn--edit-user-data"
        >
          Mis direcciones
        </router-link>
      </div>

      <button class="logout-button" type="button" @click="logout">
        <img src="@/assets/img/icons/exit.svg" alt="logout icon" />
        <span>Salir</span>
      </button>
    </div>
    <div v-else>
      <router-link :to="{ name: 'user' }" class="btn btn--edit-user-data">
        Iniciar sesión
      </router-link>
    </div>
  </div>
</template>

<script>
import { capitalizeName } from "@/features/transform";

export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    user() {
      return this.$store.state.auth.userData.user;
    },
  },
  methods: {
    capitalizeName,
    removeFromCart(item) {
      this.$store.dispatch("order/removeFromCart", item);
    },
    toggleShowUserOptions() {
      this.$store.dispatch("order/toggleShowUserOptions");
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style scoped>
.summary-box {
  right: 2rem;
}

.close-btn-container {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  align-items: center;
  gap: 1.2rem;
  justify-content: flex-end;
}

.hide-label {
  color: #767676;
  cursor: pointer;
}

.logout-button {
  color: #495057;
  font-weight: 500;
  font-size: 1.6rem;
  display: flex;
  gap: 7px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.user-options-navigation {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
</style>
