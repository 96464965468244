import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/auth/LoginView.vue";
import SignupView from "@/views/auth/SignupView.vue";
import ImpersonateView from "@/views/auth/ImpersonateView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
  },
  {
    path: "/impersonate/:impersonateToken",
    name: "impersonate",
    props: true,
    component: ImpersonateView,
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("@/views/normal-user-flow/PaymentView.vue"),
    meta: { requiresAuth: true },
  },
  // Deprecated, for reference only
  // {
  //   path: "/create-order",
  //   name: "create-order",
  //   component: () => import("@/views/admin-user-flow/CreateOrderView.vue"),
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/success",
    name: "success",
    component: () => import("@/views/normal-user-flow/SuccessOrderView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/order/:id",
    name: "order",
    props: true,
    component: () => import("@/views/orders/OrderView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/orders/MyOrdersView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/account",
    name: "user",
    component: () => import("@/views/account/UserView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/addresses",
    name: "addresses",
    component: () => import("@/views/account/AddressesView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/first-address",
    name: "first-address",
    component: () => import("@/views/normal-user-flow/AddFirstAddressView.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("userData");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
    return;
  }
  next();
});

export default router;
