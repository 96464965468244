import userService from "@/services/userService";

export const namespaced = true;

export const state = {
  selectedUser: null,
  allUsers: [],
};

export const mutations = {
  SET_ALL_USERS(state, allUsers) {
    state.allUsers = allUsers;
  },
  SET_SELECTED_USER(state, selectedUser) {
    state.selectedUser = selectedUser;
  },
};

export const actions = {
  async completeUserRegistration({ dispatch }, userRegistrationData) {
    const response = await userService.completeUserRegistration(
      userRegistrationData
    );
    dispatch("auth/updateUserDataUser", response.data.data.user, {
      root: true,
    });
  },
  async updateMe({ commit }, userData) {
    const updateMeResponse = await userService.updateMe(userData);
    commit("UPDATE_USER_DATA_USER", updateMeResponse.data.data.user);
  },
  async getAllUser({ commit }) {
    const response = await userService.getAllUser();
    commit("SET_ALL_USERS", response.data.data.users);
  },
  async getMe({ dispatch }) {
    const response = await userService.getMe();
    dispatch("auth/updateUserDataUser", response.data.data.user, {
      root: true,
    });
  },
  setSelectedUser({ commit }, selectedUser) {
    commit("SET_SELECTED_USER", selectedUser);
  },
};

export const getters = {
  getSelectedUser(state) {
    return state.selectedUser;
  },
};
