<template>
  <div
    class="summary-box"
    :class="isLoggedIn ? 'summary-box--spaced' : 'summary-box--default'"
  >
    <div class="close-btn-container">
      <button @click="toggleShowCartSummary" class="hide-label">Ocultar</button>
      <button
        class="btn-close-summary-box"
        @click="toggleShowCartSummary"
        title="Ocultar"
      >
        <img
          draggable="false"
          src="@/assets/img/icons/arrow-up.svg"
          alt="icono de carrito"
        />
      </button>
    </div>

    <div v-if="cartItems.length > 0" class="summary-box-content">
      <table>
        <tbody>
          <tr v-for="item in cartItems" :key="item">
            <th class="product-img-name-summary">
              <img
                v-if="item.product.photo"
                class="img-small-summary"
                :src="item.product.photo?.url"
                draggable="false"
                alt="product image"
              />
              <img
                v-else
                class="img-small"
                src="@/assets/img/icons/picture.svg"
                alt="no image"
              />
              <span class="product-name">{{
                Transform.capitalizeName(item.product.name)
              }}</span>
            </th>
            <td class="product-quantity">
              <span>{{ item.quantity }} gr. </span>
            </td>
            <td class="product-price">
              <span>
                $ {{ (item.product.price * item.quantity).toLocaleString() }}
              </span>
            </td>
            <td>
              <button
                class="btn-remove"
                @click="removeFromCart(item)"
                title="Quitar del carrito"
              >
                ✘
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <span class="products-total-price">
        Total: $ <strong>{{ cartTotalPrice.toLocaleString() }}</strong>
      </span>
      <GoToPaymentButtonComponent />
    </div>
    <div v-else>
      <span>Carrito vacío</span>
    </div>
  </div>
</template>

<script>
import Transform from "@/features/transform";
import GoToPaymentButtonComponent from "@/components/shared/GoToPaymentButtonComponent.vue";

export default {
  components: { GoToPaymentButtonComponent },
  data() {
    return {
      isFetchingUserAddresses: false,
    };
  },
  computed: {
    Transform() {
      return Transform;
    },
    cartItems() {
      return this.$store.state.order.newOrder.items;
    },
    cartTotalPrice() {
      return this.$store.getters["order/orderTotalPrice"];
    },
    isLoggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
  },
  methods: {
    removeFromCart(item) {
      this.$store.dispatch("order/removeFromCart", item);
    },
    toggleShowCartSummary() {
      this.$store.dispatch("order/toggleShowCartSummary");
    },
  },
};
</script>

<style scoped>
.summary-box--default {
  right: 18.5rem;
}

.summary-box--spaced {
  right: 33.7rem;
}

.img-small-summary {
  width: 6rem;
  transform: scale(1.07);
  aspect-ratio: 1 / 1;
}

.product-img-name-summary {
  display: flex;
  align-items: center;
  gap: 1rem;
}

th,
td {
  padding-right: 1.6rem;
  text-align: right;
  /* border-bottom: 1px solid #eee; */
}

th {
  font-weight: 500;
}

.products-total-price {
  padding-right: 4rem;
  text-align: right;
}

.btn-remove {
  background-color: #ff8787;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 1px 0.35rem;
  border-radius: 100rem;
  transition: all 0.3s;
}

.btn-remove:hover {
  background-color: #f03e3e;
}

.close-btn-container {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  align-items: center;
  gap: 1.2rem;
  justify-content: flex-end;
}

.hide-label {
  color: #767676;
  cursor: pointer;
}

td:last-child {
  padding-right: 0;
}

.product-name {
  line-height: 1.5;
  text-align: start;
}

.product-quantity {
  min-width: 7rem;
}

.product-price {
  min-width: 8rem;
}

@media (max-width: 34em) {
  th,
  td {
    padding-top: 1rem;
    padding-bottom: 2.4rem;
  }
}

@media (max-width: 22em) {
  .img-small-summary {
    display: none;
  }
}
</style>
