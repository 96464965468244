<template>
  <div class="header-container">
    <header id="header-desktop" class="header">
      <div class="fbc-logo-container">
        <button class="fbc-logo" @click="navigateHome">
          <span>FBC</span>
        </button>
      </div>

      <nav class="main-nav">
        <ul class="main-nav-list">
          <!-- <li>
            <a class="main-nav-link" href="#">Nosotros</a>
          </li>

          <li>
            <a class="main-nav-link" href="#">Contacto</a>
          </li> -->

          <li class="input-search-container">
            <BigInputSearchComponent v-if="showInputSearchInHeader" />
          </li>
        </ul>
      </nav>

      <div class="main-nav-list">
        <!-- cart -->
        <div
          class="main-nav-link"
          @click="toggleShowCartSummary"
          title="Click para ver items del carrito"
        >
          <div class="cart">
            <span class="cart-items" v-if="cartLength">{{ cartLength }}</span>
            <img
              draggable="false"
              src="@/assets/img/icons/cart.svg"
              alt="icono de carrito"
            />
          </div>
          <button>Carrito</button>
          <div class="cart-arrow-expand-container">
            <img
              v-if="!showCartSummary"
              draggable="false"
              src="@/assets/img/icons/arrow-down.svg"
              alt="icono de expandir carrito"
            />
          </div>
        </div>
        <CartSummaryComponentVue v-if="showCartSummary" />

        <!-- orders -->
        <router-link
          v-if="isLoggedIn"
          :to="{ name: 'orders' }"
          class="main-nav-link"
        >
          <img
            draggable="false"
            src="@/assets/img/icons/orders.svg"
            alt="icono de usuario"
          />
          <span>Pedidos</span>
        </router-link>

        <!-- account -->
        <div
          class="main-nav-link"
          @click="toggleShowUserOptions"
          title="Click para ver opciones del usuario"
        >
          <img
            draggable="false"
            src="@/assets/img/icons/user.svg"
            alt="icono de usuario"
          />
          <div v-if="isLoggedIn">
            <span>{{ userName }}</span>
          </div>
          <button v-else>Cuenta</button>

          <div class="cart-arrow-expand-container">
            <img
              v-if="isLoggedIn && !showUserOptions"
              draggable="false"
              src="@/assets/img/icons/arrow-down.svg"
              alt="icono de expandir opciones"
            />
          </div>
        </div>
        <UserOptionsComponent v-if="showUserOptions" />
      </div>
    </header>

    <!-- mobile nav -->
    <header id="header-mobile" class="header">
      <!-- toggle menu -->
      <div id="menuToggle">
        <input v-model="isMobileMenuOpen" type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          <li class="menu-options">
            <router-link
              v-if="isLoggedIn"
              :to="{ name: 'orders' }"
              class="main-nav-link"
              @click="closeMenu"
            >
              <img
                draggable="false"
                src="@/assets/img/icons/orders.svg"
                alt="icono de usuario"
              />
              <button>Pedidos</button>
            </router-link>
            <router-link
              class="main-nav-link"
              @click="closeMenu"
              :to="{ name: 'user' }"
            >
              <img
                draggable="false"
                src="@/assets/img/icons/user.svg"
                alt="icono de usuario"
              />
              <button>Cuenta</button>
            </router-link>
            <router-link
              class="main-nav-link"
              @click="closeMenu"
              :to="{ name: 'addresses' }"
            >
              <img
                draggable="false"
                src="@/assets/img/icons/address.svg"
                alt="icono de usuario"
              />
              <button>Direcciones</button>
            </router-link>
          </li>
          <li v-if="isLoggedIn">
            <button class="main-nav-link" type="button" @click="logout">
              <img src="@/assets/img/icons/exit.svg" alt="logout icon" />
              <text>Salir</text>
            </button>
          </li>
        </ul>
      </div>

      <!-- fbc logo todo: make it a component -->
      <div class="fbc-logo-container">
        <button class="fbc-logo" @click="navigateHome">
          <span>FBC</span>
        </button>
      </div>

      <!-- cart -->
      <div class="main-nav-list">
        <div
          class="main-nav-link"
          @click="toggleShowCartSummary"
          title="Click para ver items del carrito"
        >
          <div class="cart">
            <span class="cart-items" v-if="cartLength">{{ cartLength }}</span>
            <img
              draggable="false"
              src="@/assets/img/icons/cart.svg"
              alt="icono de carrito"
            />
          </div>
        </div>
        <CartSummaryComponentVue v-if="showCartSummary" />
      </div>
    </header>
  </div>
</template>

<script>
import CartSummaryComponentVue from "@/components/shared/CartSummaryComponent.vue";
import BigInputSearchComponent from "@/components/home/BigInputSearchComponent.vue";
import router from "@/router";
import UserOptionsComponent from "@/components/shared/UserOptionsComponent.vue";
import { capitalizeName } from "@/features/transform";

export default {
  data() {
    return {
      isMobileMenuOpen: false,
    };
  },
  components: {
    UserOptionsComponent,
    CartSummaryComponentVue,
    BigInputSearchComponent,
  },
  computed: {
    cartLength() {
      return this.$store.state.order.newOrder.items.length;
    },
    showInputSearchInHeader() {
      return this.$store.state.product.showInputSearchInHeader;
    },
    showCartSummary() {
      return this.$store.state.order.showCartSummary;
    },
    showUserOptions() {
      return this.$store.state.order.showUserOptions;
    },
    isLoggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    user() {
      return this.$store.state.auth.userData.user;
    },
    userName() {
      const name = this.user.firstName;

      if (!name) {
        return "User";
      }

      if (name.length > 10) {
        return capitalizeName(name.substring(0, 10) + "...");
      }
      return capitalizeName(name);
    },
  },
  methods: {
    capitalizeName,
    toggleShowCartSummary() {
      this.$store.dispatch("order/toggleShowCartSummary");
    },
    toggleShowUserOptions() {
      this.$store.dispatch("order/toggleShowUserOptions");
    },
    navigateHome() {
      this.$store.dispatch("product/setSearchFilter", "");
      router.push("/");
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.closeMenu();
    },
    closeMenu() {
      this.isMobileMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.main-nav {
  z-index: 3;
}

.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4.8rem;
}

.main-nav-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.main-nav-link > button {
  cursor: pointer;
}

.main-nav-link,
.main-nav-link:visited {
  text-decoration: none;
  color: #495057;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;
}

.header-container {
  height: 9.6rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Because we want header to be sticky later */
  height: 9.6rem;
  width: 100%;
  padding: 0 4.8rem;
  position: fixed;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  z-index: 1;
}

#header-desktop {
  display: flex;
}

#header-mobile {
  display: none;
}

.logo {
  width: 6.2rem;
}

.cart {
  position: relative;
}

.cart-items {
  width: 2.4rem;
  height: 2.4rem;
  color: #fff;
  background-color: #f03e3e;
  position: absolute;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;

  font-size: 1.4rem;

  right: 2.6rem;
  top: -2px;
}

.cart-arrow-expand-container {
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* mobile burger menu */
#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #495057;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0 0;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #495057;
}
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  height: 95vh;
  border-bottom-right-radius: 20px;
  box-shadow: 0 4px 6px rgba(32, 33, 36, 0.16);
  margin: -37px 0 0 -50px;
  padding: 12.5rem 5rem 5rem;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#menu li {
  transition-delay: 2s;
}

#menuToggle input:checked ~ ul {
  transform: none;
}

ul {
  padding: 0;
  list-style-type: none;
}

.menu-options {
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
}

/* Below 704PX (Smaller Tablets) */
@media (max-width: 44em) {
  #header-mobile {
    display: flex;
  }

  #header-desktop {
    display: none !important;
  }

  .fbc-logo-container {
    flex-direction: column;
  }
}

/* Below 544PX (Phones) */
@media (max-width: 34em) {
  .header {
    padding: 0 3.4rem;
  }
}
</style>
