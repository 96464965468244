import { apiClient } from "./config/serviceConfiguration";

export default {
  signup(user) {
    return apiClient.post("/auth/signup", user);
  },
  login(credentials) {
    return apiClient.post("/auth/login", credentials);
  },
  authWithGoogle(credential) {
    return apiClient.post("/auth/google", credential);
  },
  impersonateUser(impersonateToken) {
    return apiClient.post("/auth/impersonate", { impersonateToken });
  },
};
